import React, { useEffect, useState } from 'react'
import styles from './AgoraMembers.module.css'
import Header from '../../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingPage from '../../../components/LoadingPage/LoadingPage'
import { color } from '../../../hooks/Utils/color'
import MemberCard from './Member/MemberCard'
import { Spinner } from 'react-bootstrap'


const AgoraMembers = () => {

    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const { ago_id } = useParams()
    const { state } = useLocation();
    const { agm_user_role } = state


    // SEARCH
    const [searchText, setSearchText] = useState('')

    // MEMBERS STATE
    const [members, setMembers] = useState([])
    const [membersData, setMembersData] = useState([])
    const [allMembers, setAllMembers] = useState([])
    const [hasMoreMembers, setHasMoreMembers] = useState(true)

    // REQUESTS STATE
    const [requests, setRequests] = useState([])
    const [requestsData, setRequestsData] = useState([])
    const [allRequests, setAllRequests] = useState([])
    const [hasMoreRequests, setHasMoreRequests] = useState(true)

    // FETCH STATE
    const [page, setPage] = useState(1)
    const [isFetching, setIsFetching] = useState(true)


    useEffect(() => {
        fetchAllMembers()
        fetchMembers()
    }, [])

    const fetchAllMembers = async () => {
        try {
            const request = await api(`api/agoras/members/${ago_id}`, 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Response fetch agora members:', response?.members)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    const allRequests = response.requests
                    const requestsOfUserWhoHaveRequestToParticipate = allRequests.filter(request =>
                        request?.pivot?.agm_status === 0 &&
                        request?.pivot?.agm_type === 0,
                    )
                    setAllRequests(requestsOfUserWhoHaveRequestToParticipate)
                    setAllMembers(response.members)
                }
            }
        } catch (error) {
            // fetchAllMembers()
            console.log('Error fetch agora members:', error.message)
        }
    }



    const fetchMembers = async () => {
        try {
            const request = await api(`api/agoras/members/${ago_id}?paginate&page=${page}`, 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Fetch Agora members response:', response)
            setIsFetching(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    if (response?.members?.next_page_url) {
                        setHasMoreMembers(true)
                    } else {
                        setHasMoreMembers(false)
                    }
                    if (response?.requests?.next_page_url) {
                        setHasMoreRequests(true)
                    } else {
                        setHasMoreRequests(false)
                    }
                    setPage(page + 1)
                    const newRequests = response?.requests?.data.filter(request =>
                        request?.pivot?.agm_status === 0 &&
                        request?.pivot?.agm_type === 0
                    )
                    setRequests([...requests, ...newRequests])
                    setMembers([...members, ...response?.members?.data])

                    setRequestsData([...requests, ...newRequests])
                    setMembersData([...members, ...response?.members?.data])
                }
            }
        } catch (error) {
            // fetchMembers()
            console.error('Fetch Agora members error:', error)
        }
    }


    const handleSearch = (text) => {
        setSearchText(text)
        if (searchText) {
            const newMembers = allMembers.filter(user =>
                user?.user_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                user?.user_surname?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                user?.user_username?.toLowerCase()?.includes(searchText?.toLowerCase()),
            )
            const newRequests = allRequests.filter(user =>
                user?.user_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                user?.user_surname?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                user?.user_username?.toLowerCase()?.includes(searchText?.toLowerCase()),
            )
            setMembers(newMembers)
            setRequests(newRequests)
        } else {
            setMembers(membersData)
            setRequests(requestsData)
        }
    }


    const loader = () => {
        if (hasMoreMembers || hasMoreRequests) {
            return (
                <div style={{ textAlign: 'center', padding: '10px' }}>
                    <Spinner />
                </div>
            )
        }
    }

    return (
        <div style={{ height: '100vh', backgroundColor: 'white' }}>
            <Header title='Members' goBack={() => navigate(-1)} />
            <div className={styles.searchContainer}>
                <h5 style={{ fontWeight: 'bold', paddingLeft: '20px' }}>
                    {t('search')?.replaceAll('.', '')}
                </h5>
                <input
                    type='search'
                    placeholder={`${t('search')}...`}
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    className={styles.searchInput}
                />
            </div>
            <div id='scrollableDiv' className={styles.container}>
                {isFetching && loader()}
                <InfiniteScroll
                    dataLength={members.length + requests.length}
                    next={fetchMembers}
                    hasMore={hasMoreMembers}
                    loader={loader()}
                    scrollThreshold={0.9}
                    scrollableTarget='scrollableDiv'>
                    {requests.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary }}>
                            {t('request_to_participate')} ({allRequests.length})
                        </h6>
                    }
                    {requests.map((member, index) => {
                        return (
                            <MemberCard
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchMembers}
                            />
                        )
                    })}

                    {members.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary, marginTop: "20px" }}>
                            {t('list_of_members')} ({allMembers.length})
                        </h6>
                    }
                    {members.map((member, index) => {
                        return (
                            <MemberCard
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchMembers}
                            />
                        )
                    })}
                </InfiniteScroll>
            </div>

        </div>
    )
}

export default AgoraMembers