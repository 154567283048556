import React from 'react'
import '../AppStore/AppStore.style.css'
import Select from 'react-select'
import logo from '../../assets/images/logo.png';
import playstore from '../../assets/images/playstore.png';
import appstore from '../../assets/images/appstore.png';
import appgallery from '../../assets/images/appgallery.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';


const AppStore = () => {

  const { t } = useTranslation()
  const language = localStorage.getItem('language')
  const { UPDATE_LANGUAGE } = useReducerAuthAction()

  const languages = [
    {
      label: t('french_france'),
      value: 'fr'
    },
    {
      label: t('english_us'),
      value: 'en'
    },
  ]

  /* CHANGE LANGUAGE */
  const handleChangeLanguage = (value) => {
    i18next.changeLanguage(value)
    UPDATE_LANGUAGE(value)
  }


  return (
    <div className='appstore'>

      <div className='card-content'>

        <img className='logo' src={logo} alt='' />

        <h4 style={{ fontStyle: 'initial', fontSize: '18px', color: '#414141', }}>
          {t('with_myopinion_share_and_stay')}
        </h4>

        <a href="https://play.google.com/store/apps/details?id=com.myopinion.ci">
          <img className='img-playstore' src={playstore} alt='' />
        </a>

        <a href="https://apps.apple.com/fr/app/myopinion-r%C3%A9seau-social/id1608191428">
          <img className='img-appstore' src={appstore} alt='' />
        </a>

        <a href="https://appgallery.cloud.huawei.com/ag/n/app/C107058367?locale=en_US&source=appshare&subsource=C107058367&shareTo=com.android.bluetooth&shareFrom=appmarket&shareIds=06b3f9ddf0b14366b30a9f9f189cd555_com.android.bluetooth&callType=SHARE">
          <img className='img-appgallery' src={appgallery} alt='' />
        </a>

        <Select
          placeholder='Pays'
          options={languages}
          isSearchable={false}
          defaultValue={languages.filter(items => items.value === language)}
          onChange={newValue => handleChangeLanguage(newValue.value)}
          className='select-language'
        />

        <div className='terms-conditions'>
          <a style={{ fontSize: '13px', marginRight: '5px' }}
            href={language === 'en' ? 'https://myopinion.ci/legal/en/terms-and-conditions/' : 'https://myopinion.ci/legal/fr/conditions-generales/'}>
            {t('terms_and_conditions')}
          </a>

          <a style={{ fontSize: '13px', marginLeft: '5px' }}
            href={language === 'en' ? 'https://myopinion.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}>
            {t('privacy_policy')}
          </a>
        </div>

        <p className='copyrigth'>
          MyOpinion © {new Date().getFullYear()}
        </p>

      </div>

    </div>
  )
}

export default AppStore