import React from 'react'
import { Outlet } from 'react-router-dom'
import './AppLayout.style.css'
import Endbar from '../../components/Endbar/Endbar'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { isMobile } from 'react-device-detect';
import NavigationBottom from '../../components/NavigationButton/NavigationBottom'
import TopBar from '../../components/Topbar/Topbar'


function AppLayout() {

    const { innerWidth: width, innerHeight: height } = window;
    const currentPath = window.location.pathname;

    if (isMobile) {
        return (
            <div>
                <Outlet />
                <NavigationBottom />
            </div>
        )
    }

    return (
        <div className='app-layout' style={{width: width, height: height}}>

            <div className='sidebar-box'>
                <Sidebar />
            </div>

            <div className='outlet'>
                <Outlet />
            </div>

            <div className='endbar-box'>
                <Endbar />
            </div>
        </div>
    )
}

export default AppLayout