import React, { useEffect, useState } from 'react'
import styles from './Fans.module.css'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaUserTimes } from 'react-icons/fa'
import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { color } from 'hooks/Utils/color'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from '@mui/material'
import UserCard from './UserCard/UserCard'
import { FaUserCircle } from 'react-icons/fa'


const Fans = ({ user_id }) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()

    const controller = new AbortController();
    const signal = controller.signal;

    const [fans, setFans] = useState([])
    const [search, setSearch] = useState('')

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isFetching, setIsFetching] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)


    useEffect(() => {
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])


    const fetchData = async () => {
        try {
            const request = await api(`api/user/audience/fans/${user_id}?page=${1}`, 'GET', null, accessToken, signal)
            const response = await request.json()
            // console.log('Fetch fans response:', response?.fans?.data)
            setIsFetching(false)
            if (request?.status === 200) {
                if (response?.fans?.next_page_url) {
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
                if (response?.fans?.data?.length === 0) {
                    setIsEmpty(true)
                }
                setData(response?.fans?.data)
                setFans(response?.fans?.data)
                setPage(2)
            }
        } catch (error) {
            // fetchData()
            console.error('Fetch fans error:', error);
        }
    }


    const loadMore = async () => {
        if (!isLoading && hasMore && !search) {
            try {
                setIsLoading(true)
                const request = await api(`api/user/audience/fans/${user_id}?page=${page}`, 'GET', null, accessToken)
                const response = await request.json()
                // console.log('Fetch fans response:', response?.fans?.data[0])
                setIsLoading(false)
                if (request?.status === 200) {
                    if (response?.fans?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    setData([...data, ...response?.fans?.data])
                    setFans([...data, ...response?.fans?.data])
                    setPage(page + 1)
                }
            } catch (error) {
                //   loadNextPage()
                console.error('Fetch fans error:', error);
            }
        }
    }


    if (isEmpty) {
        return (
            <div style={{ paddingTop: '30vh', textAlign: 'center' }}>
                <FaUserCircle color='#808080' style={{ width: '50px', height: '50px' }} />
                <h6 style={{ color: '#808080', fontWeight: '500', marginTop: '10px' }}>
                    {t('no_data_available')}
                </h6>
            </div>
        )
    }


    return (
        <div id="scrollableDiv" className={styles.container}>
            <InfiniteScroll
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<div style={{ textAlign: 'center', padding: '15px' }}><Spinner /></div>}
                scrollableTarget="scrollableDiv">
                {data.map((item, index) => {
                    return (
                        <div key={index.toString()}>
                            <UserCard
                                user_id={user_id}
                                user={{...item, profile: {prof_picture: item?.prof_picture}}}
                            />
                        </div>
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default Fans