const backgroundColorOpinion = [
    '#FFFFFF',
    '#0F056B',
    '#D60707',
    '#800080',
    '#087DE5',
    '#07B53C',
    '#800000',
    '#D6636F',
    '#FF5733',
    '#FFA500',
]

export default backgroundColorOpinion