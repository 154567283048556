import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const LoadingPage = ({
    text = 'Loading...'
}) => {

    const { t } = useTranslation()


    return (
        <div style={{backgroundColor: 'white', width: '100%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            <div style={{ textAlign: 'center' }}>

                <Spinner
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    variant='secondary'
                    style={{
                        width: '30px',
                        height: '30px'
                    }}
                />

                <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                    {text}
                </h6>

            </div>

        </div>
    )
}

export default LoadingPage