import React, { useState } from 'react'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next'
import { redirect, useNavigate } from 'react-router-dom'
import { api } from '../../api/api'
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit'
import TextInput from '../../components/TextInput/TextInput'
import { ValidateConfirmPassword, ValidateEmail, ValidatePassword, ValidatePseudo } from '../../hooks/Utils/parsing'
import './Register.style.css'
import { useEffect } from 'react';
import Select from 'react-select'
import myopinion_text from '../../assets/images/myopinion_text.png';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';


const Register = () => {

  // STATES
  const { t } = useTranslation()
  const navigate = useNavigate()
  const language = localStorage.getItem('language')
  const {UPDATE_LANGUAGE} = useReducerAuthAction()
  const [isLoading, setIsloading] = useState(false)

  const [countries, setCountries] = useState([])

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [gender, setGender] = useState('M');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const genders = [
    {
      value: 'M',
      label: 'Male',
    },
    {
      value: 'F',
      label: 'Female',
    },
    {
      value: 'A',
      label: 'Autre',
    },
  ]

  const languages = [
    {
      label: t('french_france'),
      value: 'fr'
    },
    {
      label: t('english_us'),
      value: 'en'
    },
  ]

  /* CHANGE LANGUAGE */
  const handleChangeLanguage = (value) => {
    i18next.changeLanguage(value)
    UPDATE_LANGUAGE(value)
  }

  useEffect(() => {
    const getCountries = async () => {
      try {
        const request = await api('api/get-countries', 'GET', null)
        const response = await request.json()
        // console.log(response, 'responseeeee of get countries')
        if (request.ok && request.status === 200) {
          const countries = response.countries.map(c => ({
            label: c.French_Name,
            value: c.Sort_Order,
            key: c.ITU_T_Telephone_Code,
          }))
          setCountries(countries)
        }
      } catch (error) {
        getCountries()
        console.log('Erreur', error.message)
      }
    }
    getCountries()
  }, [])


  /* CHECK FORM */
  const checkForm = async (e) => {
    e.preventDefault();
    if (surname.trim().length < 3) {
      setError(t('your_surname_must_contain_at_least_4_characters'))
      return
    }
    if (!ValidatePseudo(pseudo.trim())) {
      setError(t('your_nickname_must_contain_at_least_4_characters_only_underscore'))
      return
    }
    if (!ValidateEmail(email.trim())) {
      setError(t('invalid_email'))
      return
    }
    if (password.length < 8) {
      setError(t('invalid_password'))
      return
    }
    if (!ValidateConfirmPassword(password, confirmPassword)) {
      setError(t('passwords_do_not_match'))
      return
    }
    setError('')
    handleSubmit()
  }

  /* LOGIN HANDLER */
  const handleSubmit = async (e) => {
    setIsloading(true)
    try {
      const body = {
        user_surname: surname.trim(),
        user_name: name.trim(),
        user_username: pseudo.trim(),
        prof_telephone: phone.trim(),
        user_pays: country,
        user_ville: city.trim(),
        user_email: email?.toLowerCase().trim(),
        user_email_confirm: email?.toLowerCase().trim(),
        user_password: password,
        user_password_confirm: confirmPassword,
        user_pays: country,
        user_civility: gender,
        user_langue: localStorage.getItem('language'),
      }
      const request = await api('api/store-user', 'POST', body)
      const response = await request.json()
      console.log('Response store user:', response)
      if (request.ok && request.status === 200) {
        confirmAccount()
        setError('')
      } else {
        setIsloading(false)
        setError(t('the_email_is_already_taken'))
      }
    } catch (error) {
      setError(t('no_internet_connection'));
      setIsloading(false)
      console.warn(error.message);
    }
  };

  const confirmAccount = async () => {
    try {
      const body = { user_email: email?.toLowerCase() }
      const request = await api('api/email/confirmation', 'POST', body)
      const response = await request.json()
      console.log('response confirm email:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {
          navigate("/confirm-password", { state: { email: email } })
        } else {
          setError(response.message)
        }
      } else {
        setError(response.error.user_email)
      }
      setIsloading(false)
    } catch (error) {
      confirmAccount()
      setError(error.message)
    }
  }



  return (
    <div className='register'>

      <div className='card-form '>

      <img style={{width: '50%', marginTop: '10%'}} src={myopinion_text} alt='' />

        <h3 className='title'>{t('create_an_account')}</h3>

        <p className='subtitle'>
          {t('you_do_not_have_an_account')}
        </p>

        <div className='error-message'>
          {error}
        </div>

        <form onSubmit={checkForm} >

          <div className='input-container'>
            <TextInput
              value={surname}
              setValue={setSurname}
              type='text'
              placeholder={`${t('surname')}*`}
              style={{ width: '48%' }}
            />
            <TextInput
              value={name}
              setValue={setName}
              type='text'
              placeholder={`${t('name')}*`}
              style={{ width: '48%' }}
            />
          </div>

          <TextInput
            value={pseudo}
            setValue={setPseudo}
            type='text'
            placeholder={`${t('nickname')}*`}
          />

          <TextInput
            value={email}
            setValue={setEmail}
            type='text'
            placeholder={`${t('email')}*`}
          />

          <TextInput
            value={password}
            setValue={setPassword}
            type='password'
            placeholder={`${t('password')}*`}
          />

          <TextInput
            value={confirmPassword}
            setValue={setConfirmPassword}
            type='password'
            placeholder={`${t('confirm_password')}*`}
          />

          <div className='input-container'>
            <div className='drop-select'>
              <Select
                placeholder={t('country')}
                options={countries}
                defaultValue={countries[0]}
                onChange={newValue => setCountry(newValue.value)}
              />
            </div>
            <TextInput
              value={city}
              setValue={setCity}
              type='text'
              placeholder={t('city')}
              style={{ width: '48%' }}
            />
          </div>

          <div className='input-container'>

            <TextInput
              value={phone}
              setValue={setPhone}
              type='phone'
              placeholder={t('phone_number')}
              style={{ width: '48%' }}
            />

            <div className='drop-select'>
              <Select
                placeholder={t('civility')}
                options={genders}
                defaultValue={genders[0]}
                scrollable={false}
                onChange={newValue => setGender(newValue.value)}
              />
            </div>

          </div>

          <div className='language'>
            <Select
              placeholder='Pays'
              options={languages}
              isSearchable={false}
              defaultValue={languages.filter(items => items.value === language)}
              onChange={newValue => handleChangeLanguage(newValue.value)}
            />
          </div>

          <ButtonSubmit
            value={t('register')}
            isLoading={isLoading}
            loadBoostrapColor={'light'}
            style={{
              width: '100%',
              color: 'white',
              marginBottom: '5%',
              backgroundColor: '#1880a1'
            }}
          />

        </form>


        {language == 'fr' ?
          <p className='policy'>
            En cliquant sur le button s'inscrire vous confirmez avoir lu et acceptez
            <a href='#'> les conditions générales</a> et
            <a href='#'> la politique de confidentialité</a> de My Opinion.
          </p>
          :
          <p className='policy'>
            By clicking on the register button you confirm that you have read and approved
            <a href='#'> the general conditions</a> and
            <a href='#'> conditions and the privacy policy</a> of My Opinion.
          </p>
        }

        <p className='copyrigth'>
          MyOpinion © {new Date().getFullYear()}
        </p>

      </div>

    </div>
  )
}

export default Register