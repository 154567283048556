import React, { useState } from 'react'
import styles from './Support.module.css'
import TextInput from 'components/TextInput/TextInput';
import { FaCheckCircle } from 'react-icons/fa'
import { color } from 'hooks/Utils/color';
import { BsTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import i18next from 'i18next';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { api } from 'api/api';
import { Spinner } from 'react-bootstrap'


const Support = () => {

    // STATES
    const { t, i18n } = useTranslation()

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSended, setIsSended] = useState(false);

    const language = localStorage.getItem('language')
    const languages = [
        {
            label: t('french_france'),
            value: 'fr'
        },
        {
            label: t('english_us'),
            value: 'en'
        },
    ]

    const handleChangeLanguage = (value) => {
        i18next.changeLanguage(value)
    }



    const handleSubmit = async (e) => {
        try {
            if (!isLoading) {
                e.preventDefault();
                setError(null)
                setIsLoading(true)
                const body = {
                    full_name: name,
                    email_address: email,
                    subject: i18n.language == 'en' ? 'MyOpinion Support Request' : `MyOpinion Demande d'Assistance`,
                    message: message
                }
                console.log(body)
                const request = await api('api/support/sendMessage', 'POST', body)
                const response = await request.json()
                setIsLoading(false)
                console.log(response)
                if (request.status === 200) {
                    if (response?.success) {
                        setIsSended(true)
                    } else {
                        setError(t('an_error_occurred_please_try_again_later'));
                    }
                }
            }
        } catch (error) {
            setIsLoading(false)
            setError(t('an_error_occurred_please_try_again_later'));
            console.warn(error.message);
        }
    };

    if (isSended) {
        return (
            <div className={styles.page}>
                <div className={styles.container}>
                    <Select
                        placeholder='Langue'
                        options={languages}
                        isSearchable={false}
                        defaultValue={languages.filter(items => items.value === language)}
                        onChange={newValue => handleChangeLanguage(newValue.value)}
                        className={styles.selectLanguageInput}
                    />
                    <div className={styles.imageBox}>
                        <img src={require('../../assets/images/logo.png')}
                            width={100}
                            alt=''
                        />
                    </div>
                    <h4 className={styles.titlePage}>
                        {i18n.language == 'en'
                            ? 'Contact us'
                            : 'Contactez-nous'
                        }
                    </h4>

                    <div className={styles.imageBox}>
                        <FaCheckCircle color={color.success} size={60} style={{ marginTop: '30px' }} />
                    </div>
                    <p style={{ textAlign: 'center' }}>
                        {i18n.language == 'en'
                            ? 'Your message has been sent successfully.'
                            : 'Votre message a été envoyé avec succès.'
                        }
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.page}>

            <div className={styles.container}>

                <Select
                    placeholder='Langue'
                    options={languages}
                    isSearchable={false}
                    defaultValue={languages.filter(items => items.value === language)}
                    onChange={newValue => handleChangeLanguage(newValue.value)}
                    className={styles.selectLanguageInput}
                />

                <div className={styles.imageBox}>
                    <img src={require('../../assets/images/logo.png')}
                        width={100}
                        alt=''
                    />
                </div>

                <h4 className={styles.titlePage}>
                    {i18n.language == 'en'
                        ? 'Contact us'
                        : 'Contactez-nous'
                    }
                </h4>

                <p className={styles.subtitlePage}>
                    {i18n.language == 'en'
                        ? 'The MyOpinion Help Center is a great way to get information about issues you might be experiencing without having to try to contact a customer service representative.'
                        : 'Le centre d\'aide MyOpinion est un excellent moyen d\'obtenir des informations sur les problèmes que vous pourriez rencontrer sans avoir à essayer de contacter un représentant du service client.'
                    }
                </p>

                <div className={styles.contactContainer}>

                    <div className={styles.contactBox}>
                        <div className={styles.iconBox} style={{backgroundColor: color.primary}}>
                            <IoMail size={20} color='white' />
                        </div>
                        <div>
                            <h6 style={{ fontWeight: '500', fontSize: '15px', color: color.primary, margin: 0 }}>
                                {i18n.language == 'en'
                                    ? 'E-mail address'
                                    : 'Adresse Email'
                                }
                            </h6>
                            <h5 style={{ margin: 0 }}>
                                support@myopinion.ci
                            </h5>
                        </div>
                    </div>

                    <div className={styles.contactBox}>
                        <div className={styles.iconBox} style={{backgroundColor: color.primary}}>
                            <BsTelephoneFill size={20} color='white' />
                        </div>
                        <div>
                            <h6 style={{ fontWeight: '500', fontSize: '15px', color: color.primary, margin: 0 }}>
                                {i18n.language == 'en'
                                    ? 'Phone'
                                    : 'Téléphone'
                                }
                            </h6>
                            <h5 style={{ margin: 0 }}>
                                +225 0505340303
                            </h5>
                        </div>
                    </div>

                </div>

                <div className={styles.formBox}>
                    <form onSubmit={handleSubmit} >

                        <h5 className={styles.titleContact}>
                            {i18n.language == 'en'
                                ? 'Send a message'
                                : 'Envoyer un message'
                            }
                        </h5>

                        {error &&
                            <Alert severity="error"
                                style={{
                                    marginBottom: '20px'
                                }}>
                                {error}
                            </Alert>
                        }

                        <div className={styles.inputBox}>
                            <label className={styles.label}>
                                {i18n.language == 'en'
                                    ? 'Your name'
                                    : 'Votre nom'
                                }
                            </label>
                            <input className={styles.input} type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className={styles.inputBox}>
                            <label>
                                {i18n.language == 'en'
                                    ? 'Your email'
                                    : 'Votre email'
                                }
                            </label>
                            <input className={styles.input} type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className={styles.inputBox}>
                            <label>Message</label>
                            <textarea className={styles.textarea} value={message} onChange={(e) => setMessage(e.target.value)} required />
                        </div>
                        <div className={styles.imageBox}>
                            <button className={styles.button} type="submit" style={{backgroundColor: color.primary}}>
                                {!isLoading ?
                                    i18n.language == 'en'
                                        ? 'Send'
                                        : 'Envoyer'
                                    :
                                    <Spinner
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        variant={'light'}
                                    />
                                }
                            </button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    );
}

export default Support