import React from 'react'
import './Home.style.css'
import Topbar from '../../components/Topbar/Topbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import HomeOpinions from '../../components/Opinions/HomeOpinions/HomeOpinions';
import Endbar from '../../components/Endbar/Endbar';
import { usePeopleMatchReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import { isMobile } from 'react-device-detect';
import TopBar from '../../components/Topbar/Topbar';
import HelmetSeo from 'components/HelmetSeo/HelmetSeo';


function Home() {

  // STATE
  const { t, i18n } = useTranslation()
  const { data: peopleMatchData } = usePeopleMatchReducer()

  return (
    <div className='home'>
      <HelmetSeo
        title={`${
          i18n.language === 'en'
          ? 'Potes - MyOpinion'
          :  'Buddies - MyOpinion'
        }`}
        description={t('login_now_to_interact_with_thousands_of_people')}
      />
      {isMobile ?
        <TopBar />
        :
        <div className='header'>
          <h5 className='title'>
            {t('buddies')}
          </h5>
        </div>
      }
      <HomeOpinions />
    </div>
  )
}

export default Home