import React, { useCallback, useState } from 'react';
import styles from './DropdownAgoras.module.css'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks';
import { api } from '../../api/api';
import { useEffect } from 'react';
import { color } from '../../hooks/Utils/color';

const DropdownAgoras = ({
    title = '',
    value = '',
    setValue = () => null,
    containerStyle
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const controller = new AbortController();
    const signal = controller.signal;

    // INPUT STATE
    const [agoras, setAgoras] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(value);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const request = await api(`api/agoras`, 'GET', {}, accessToken, signal)
                const response = await request.json()
                // console.log('Fetch agoras response:', response?.agoras[0])
                setIsLoading(false)
                if (request?.ok && request?.status === 200) {

                    const agoras = response?.agoras

                    setAgoras(agoras)
                    const valueSelected = agoras?.filter((data) =>
                        data?.ago_id == value?.ago_id
                    )
                    setValue(valueSelected[0] || agoras[0])

                    const formattedOptions = agoras?.map((item) => ({
                        value: item?.ago_id,
                        label: item?.ago_title,
                    }));
                    setOptions(formattedOptions);


                   const selectedOptionDefaut = formattedOptions?.filter((data) =>
                        data?.value == value?.ago_id
                    )
                    setSelectedOption(selectedOptionDefaut[0])

                }
            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching options:', error);
            }
        }
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])


    return (
        <div className={`${styles.container} ${containerStyle}`}>

            {title && <span className={styles.titleInput}>{title}</span>}
            <div className={styles.selectInput}>
                <Select
                    placeholder={title}
                    defaultValue={selectedOption}
                    value={selectedOption}
                    onChange={(newValue) => {
                        setSelectedOption(newValue)
                        const valueSelected = agoras?.filter((data) =>
                            data?.ago_id == newValue?.value
                        )
                        setValue(valueSelected[0])
                    }}
                    options={options}
                    isSearchable={true}
                    isLoading={isLoading}
                    isClearable
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            minHeight: '30px',
                            minHeight: '30px',
                            border: state.isFocused ? `1px solid #01195f` : "1px solid #cccccc",
                            boxShadow: "none",
                            "&:hover": {
                                border: "1px solid #01195f",
                                boxShadow: "none"
                            }
                        }),

                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: '30px',
                            padding: '0 6px'
                        }),

                        input: (provided, state) => ({
                            ...provided,
                            margin: '0px',
                        }),
                        indicatorSeparator: state => ({
                            display: 'none',
                        }),
                        indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: '30px',
                        }),
                    }}
                />
            </div>
        </div>
    )
};

export default DropdownAgoras