import React, { useEffect, useState } from 'react'
import SondVideo from '../OpinionBody/SondVideo/SondVideo'

const SondAttachment = ({
    attachment,
    width = 100,
    height = '400px'
}) => {

    return (
        <>
            {attachment?.attach_url &&
                <div style={{ width: `${width - 1}%`, minHeight: '400px',  height: height, margin: '5px', }} >
                    {(attachment?.attach_type == "image") &&
                        <div style={{ backgroundColor: '#c0c0c0'}}>
                            <img src={attachment?.attach_url}
                                alt=''
                                style={{
                                    width: `${width}%`,
                                    height: height,
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    border: '1px white solid',
                                }}
                            />
                        </div>
                    }
                    {(attachment?.attach_type == "video") &&
                        <div style={{
                            width: `${width}%`,
                            height: height,
                            border: '1px white solid'
                        }}>
                            <SondVideo
                                uri={attachment?.attach_url}
                                thumbnail={attachment?.attach_thumbnail}
                                height={height}
                            />
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default SondAttachment